define(["require", "exports", "./jwt"], function (require, exports, jwt_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.createTraceHeaderValue = exports.traceHeaderName = void 0;
    /**
     * The name of the HTTP header used for traceability in the requests.
     */
    exports.traceHeaderName = "opc-request-id";
    /**
     * Creates a traceability value according to
     * https://confluence.oraclecorp.com/confluence/display/OSP/OCC+Distributed+Tracing+Considerations
     *
     * @param accessToken - the AT used for the current query or undefined
     * @returns - a HTTP header value
     */
    const createTraceHeaderValue = (accessToken) => {
        return `${accessToken !== undefined ? (0, jwt_1.parseJwt)(accessToken).jti : ""}/${crypto.randomUUID().toString().replace(/-/g, "")}`;
    };
    exports.createTraceHeaderValue = createTraceHeaderValue;
});
