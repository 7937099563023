define(["require", "exports", "preact/hooks"], function (require, exports, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.useConfigOverride = void 0;
    const useConfigOverride = () => {
        const [configOverride, setConfigOverride] = (0, hooks_1.useState)({});
        (0, hooks_1.useEffect)(() => {
            setConfigOverride(JSON.parse(sessionStorage.getItem("config") || "{}"));
        }, []);
        return configOverride;
    };
    exports.useConfigOverride = useConfigOverride;
});
