define(["require", "exports", "@os/os-common/lib/ioc", "@os/os-common/lib/services/OracleGuidedLearningService"], function (require, exports, ioc_1, OracleGuidedLearningService_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.oglChangeRoute = exports.oglInit = void 0;
    const oracleGuidedLearningService = ioc_1.container.get(OracleGuidedLearningService_1.OracleGuidedLearningService);
    const oglInit = (user, oglAppId, page) => {
        oracleGuidedLearningService.init({
            applicationId: oglAppId,
            userId: user.email,
            url: window.location.href,
            pageId: page,
            pageName: page,
            language: user.defaultLanguage,
            route: page,
            isProd: oglIsProd() ? "prod" : "dev",
            inIframe: false
        });
    };
    exports.oglInit = oglInit;
    const oglChangeRoute = (route) => {
        if (iridize) {
            iridize("api.route.update", { route });
        }
    };
    exports.oglChangeRoute = oglChangeRoute;
    const oglIsProd = () => {
        const url = window.location.href;
        return !["localhost", "dev", "test"].some(env => url.includes(env));
    };
});
