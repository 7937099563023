define(["require", "exports", "@os/os-common/lib/ioc", "@os/os-common/lib/services/AdobeAnalyticsService"], function (require, exports, ioc_1, AdobeAnalyticsService_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.trackAnalytics = exports.initAnalytics = void 0;
    const adobeAnalyticsService = ioc_1.container.get(AdobeAnalyticsService_1.AdobeAnalyticsService);
    const isLinkAnalytics = (obj) => obj != null && obj.linkName !== undefined && obj.linkName !== "";
    const isPageAnalytics = (obj) => obj != null && obj.pageName !== undefined && obj.pageName !== "";
    const initAnalytics = (enableAnalyitics) => adobeAnalyticsService.init({ enableAnalyitics });
    exports.initAnalytics = initAnalytics;
    const trackAnalytics = (analytics) => {
        if (isLinkAnalytics(analytics)) {
            adobeAnalyticsService.trackLink(analytics.linkName, analytics.linkType);
        }
        if (isPageAnalytics(analytics)) {
            adobeAnalyticsService.track(analytics);
        }
    };
    exports.trackAnalytics = trackAnalytics;
});
