define(["require", "exports", "../../utils/environment", "../../utils/pluginUrl"], function (require, exports, environment_1, pluginUrl_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.reducer = exports.setError = exports.setPluginUnhandledException = exports.setWindowHeight = exports.setContentLoaded = exports.setLanguage = exports.setPluginUrl = exports.setPlugin = exports.initialState = void 0;
    exports.initialState = {
        plugin: {},
        bootstrap: {},
        preFetch: {},
        isContentLoaded: false,
        windowHeight: "",
        pluginUnhandledException: undefined,
        error: undefined
    };
    const setPlugin = (data) => ({ type: "setPlugin", data });
    exports.setPlugin = setPlugin;
    const setPluginUrl = (data) => ({ type: "setPluginUrl", data });
    exports.setPluginUrl = setPluginUrl;
    const setLanguage = (data) => ({ type: "setLanguage", data });
    exports.setLanguage = setLanguage;
    const setContentLoaded = () => ({ type: "setContentLoaded" });
    exports.setContentLoaded = setContentLoaded;
    const setWindowHeight = (data) => ({ type: "setWindowHeight", data });
    exports.setWindowHeight = setWindowHeight;
    const setPluginUnhandledException = (data) => ({ type: "setPluginUnhandledException", data });
    exports.setPluginUnhandledException = setPluginUnhandledException;
    const setError = (data) => ({ type: "setError", data });
    exports.setError = setError;
    const reducer = (state, action) => {
        var _a, _b, _c, _d, _e, _f, _g;
        (0, environment_1.debug)("[OCC] reducer action", action);
        switch (action.type) {
            case "setPlugin":
                if (action.data) {
                    return Object.assign(Object.assign({}, exports.initialState), { plugin: {
                            url: (0, pluginUrl_1.appendPluginSearchParams)((!((_a = action.data.options) === null || _a === void 0 ? void 0 : _a.httpHeaderAuth) && !((_b = action.data.url) === null || _b === void 0 ? void 0 : _b.split("/").some(ps => ps.match(/^:.+/)))) ? action.data.url : undefined),
                            path: action.data.path,
                            allowedOrigins: ((_c = action.data.options) === null || _c === void 0 ? void 0 : _c.httpHeaderAuth) && action.data.url ? [new URL(action.data.url).origin] : [],
                            options: action.data.options
                        }, bootstrap: {
                            url: action.data.bootstrapUrl
                        }, preFetch: {
                            url: ((_d = action.data.options) === null || _d === void 0 ? void 0 : _d.httpHeaderAuth) ? action.data.url : undefined
                        } });
                }
                return state;
            case "setPluginUrl":
                return Object.assign(Object.assign({}, state), { plugin: Object.assign(Object.assign({}, state.plugin), { url: (0, pluginUrl_1.appendPluginSearchParams)((!((_e = state.plugin.options) === null || _e === void 0 ? void 0 : _e.httpHeaderAuth) || ((_f = state.plugin.options) === null || _f === void 0 ? void 0 : _f.httpHeaderAuth) && state.preFetch.url) ? action.data : undefined) }), preFetch: {
                        url: ((_g = state.plugin.options) === null || _g === void 0 ? void 0 : _g.httpHeaderAuth) && !state.preFetch.url ? action.data : undefined
                    } });
            case "setLanguage":
                if (action.data) {
                    if (!state.bootstrap.params) {
                        return Object.assign(Object.assign({}, state), { bootstrap: Object.assign(Object.assign({}, state.bootstrap), { params: { languageCode: action.data } }) });
                    }
                }
                return state;
            case "setContentLoaded":
                return Object.assign(Object.assign({}, state), { isContentLoaded: true });
            case "setWindowHeight":
                return Object.assign(Object.assign({}, state), { windowHeight: action.data });
            case "setPluginUnhandledException":
                return Object.assign(Object.assign({}, state), { pluginUnhandledException: action.data });
            case "setError":
                return Object.assign(Object.assign({}, state), { error: { type: action.data } });
            default:
                (0, environment_1.debug)("[OCC] Invalid reducer action", action);
                return state;
        }
    };
    exports.reducer = reducer;
});
