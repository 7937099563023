define(["require", "exports", "preact/hooks", "./useQuery", "../utils/environment", "../utils/traceability"], function (require, exports, hooks_1, useQuery_1, environment_1, traceability_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.useNotificationActionMutation = exports.useRecentActivityMutation = exports.useUserMutation = exports.useBootstrapMutation = void 0;
    const useMutation = (method) => (path) => (accessToken) => {
        const [loading, setLoading] = (0, hooks_1.useState)(false);
        const [error, setError] = (0, hooks_1.useState)();
        const [response, setResponse] = (0, hooks_1.useState)();
        const invoke = (0, hooks_1.useCallback)((args, onSuccess = _ => { }) => {
            setLoading(true);
            fetch(path, {
                method: method,
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                    [traceability_1.traceHeaderName]: (0, traceability_1.createTraceHeaderValue)(accessToken)
                },
                body: JSON.stringify(args)
            })
                .then(response => {
                if (!response.ok) {
                    throw new useQuery_1.HttpException(path, response.status);
                }
                return response.json();
            })
                .then(json => {
                setResponse(json);
                onSuccess(json);
            })
                .catch(setError)
                .finally(() => setLoading(false));
        }, [path, accessToken]);
        return {
            invoke,
            result: { loading, response, error }
        };
    };
    const useBootstrapMutation = (path, accessToken) => useMutation("POST")(path)(accessToken);
    exports.useBootstrapMutation = useBootstrapMutation;
    exports.useUserMutation = useMutation("PUT")(`${environment_1.apiUrl}/user`);
    exports.useRecentActivityMutation = useMutation("POST")(`${environment_1.apiUrl}/recent-activities`);
    exports.useNotificationActionMutation = useMutation("PUT")(`${environment_1.apiUrl}/notifications/actions/action`);
});
