define(["require", "exports", "preact/jsx-runtime", "preact", "preact/hooks"], function (require, exports, jsx_runtime_1, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.SearchProvider = exports.SearchContext = void 0;
    exports.SearchContext = (0, preact_1.createContext)({ searchValue: undefined, setSearchValue: undefined });
    const SearchProvider = ({ children }) => {
        const [searchText, setSearchText] = (0, hooks_1.useState)(undefined);
        const setSearchValue = (0, hooks_1.useCallback)((sv) => {
            if (searchText !== sv) {
                setSearchText(sv);
            }
        }, [searchText]);
        return ((0, jsx_runtime_1.jsx)(exports.SearchContext.Provider, { value: { searchValue: searchText, setSearchValue }, children: children }));
    };
    exports.SearchProvider = SearchProvider;
});
