define(["require", "exports", "preact/hooks", "../utils/environment", "../utils/jwt", "../utils/traceability"], function (require, exports, hooks_1, environment_1, jwt_1, traceability_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.useLogin = void 0;
    const emailWhitelist = [
        "osp-selenium-user1_ww@oracle.com",
        "osp-selenium-user2_ww@oracle.com",
        "osp-selenium-user3_ww@oracle.com",
        "osp-notification-user_ww@oracle.com"
    ];
    const login = (environment) => () => {
        const state = crypto.randomUUID();
        const nonce = crypto.randomUUID();
        sessionStorage.setItem("oauth", JSON.stringify({ state, nonce, path: window.location.pathname + window.location.search }));
        window.location.href = `${environment === null || environment === void 0 ? void 0 : environment.idcs}/oauth2/v1/authorize` +
            `?client_id=${environment === null || environment === void 0 ? void 0 : environment.idcsClientId}` +
            "&response_type=code" +
            `&redirect_uri=${encodeURIComponent(window.location.origin)}` +
            "&scope=openid%20urn:oracle:osp:osp-occ-framework" +
            `&nonce=${nonce}` +
            `&state=${state}`;
    };
    const logout = (environment) => (token) => () => {
        sessionStorage.removeItem("oauth");
        window.location.href = `${environment === null || environment === void 0 ? void 0 : environment.idcs}/oauth2/v1/userlogout` +
            `?id_token_hint=${token}` +
            `&post_logout_redirect_uri=${encodeURIComponent(window.location.origin + "/")}`;
    };
    const setTokenExpiryTimer = (expEpochInSec, setExpired) => {
        const nowEpochInSec = Math.floor(new Date().getTime() / 1000.0);
        setTimeout(() => setExpired(true), (expEpochInSec - nowEpochInSec) * 1000);
    };
    const redirectInternalUsers = (username, redirectUrl) => {
        const formattedUsername = username.trim().toLowerCase();
        if (!emailWhitelist.includes(formattedUsername) && formattedUsername.endsWith("@oracle.com") && redirectUrl) {
            sessionStorage.removeItem("oauth");
            window.location.href = redirectUrl;
            return true;
        }
        return false;
    };
    const useLogin = (environment) => {
        const [userLogin, setUserLogin] = (0, hooks_1.useState)("");
        const [accessToken, setAccessToken] = (0, hooks_1.useState)("");
        const [accessTokenExpired, setAccessTokenExpired] = (0, hooks_1.useState)(false);
        const [idToken, setIdToken] = (0, hooks_1.useState)("");
        const [loading, setLoading] = (0, hooks_1.useState)(false);
        const [error, setError] = (0, hooks_1.useState)();
        (0, hooks_1.useEffect)(() => {
            if (!environment) {
                setLoading(true);
                return;
            }
            const searchParams = new URLSearchParams(window.location.search);
            const { state, nonce, at, it, exp, path } = JSON.parse(sessionStorage.getItem("oauth") || "{}");
            if (searchParams.has("reset")) {
                sessionStorage.removeItem("oauth");
                setAccessToken("");
                setIdToken("");
                setUserLogin("");
                setLoading(false);
                window.history.pushState({}, document.title, window.location.pathname);
            }
            else if (searchParams.has("code") && searchParams.has("state")) {
                const receivedState = searchParams.get("state");
                if (receivedState !== state) {
                    return;
                }
                // Get the tokens using the received authorization code
                setLoading(true);
                const code = searchParams.get("code");
                fetch(`${environment_1.apiUrl}/auth/login`, {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        [traceability_1.traceHeaderName]: (0, traceability_1.createTraceHeaderValue)(undefined)
                    }),
                    body: JSON.stringify({ code, nonce })
                })
                    .then(response => {
                    if (!response.ok) {
                        throw new Error("Login failed");
                    }
                    return response;
                })
                    .then(response => response.json())
                    .then(body => {
                    const idToken = (0, jwt_1.parseJwt)(body.idToken);
                    if (!redirectInternalUsers(idToken.sub, environment.features.redirectInternal)) {
                        setUserLogin(idToken.user_displayname || idToken.sub);
                        setIdToken(body.idToken);
                        setAccessToken(body.accessToken);
                        sessionStorage.setItem("oauth", JSON.stringify({ at: body.accessToken, it: body.idToken, exp: body.expiresAt }));
                        setTokenExpiryTimer(body.expiresAt, setAccessTokenExpired);
                    }
                })
                    .catch(err => {
                    setError(err);
                    sessionStorage.removeItem("oauth");
                })
                    .finally(() => setLoading(false));
                // Clear the search parameter from the url
                window.history.pushState({}, document.title, path !== null && path !== void 0 ? path : window.location.pathname);
            }
            else if (at && it && Math.floor(new Date().getTime() / 1000.0) < exp) {
                const idToken = (0, jwt_1.parseJwt)(it);
                if (!redirectInternalUsers(idToken.sub, environment.features.redirectInternal)) {
                    setLoading(false);
                    setAccessToken(at);
                    setIdToken(it);
                    setUserLogin(idToken.user_displayname || idToken.sub);
                    setTokenExpiryTimer(exp, setAccessTokenExpired);
                }
            }
            else {
                sessionStorage.removeItem("oauth");
                if (!(0, environment_1.isPublicZone)(environment) &&
                    (window.location.pathname !== "/" || (environment === null || environment === void 0 ? void 0 : environment.features.autoLogin) || searchParams.has("login"))) {
                    login(environment)();
                }
                else {
                    setLoading(false);
                }
            }
        }, [environment]);
        return {
            loading,
            error,
            userLogin,
            idToken,
            accessToken,
            accessTokenExpired,
            login: login(environment),
            logout: logout(environment)(idToken)
        };
    };
    exports.useLogin = useLogin;
});
