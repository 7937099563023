define(["require", "exports", "preact/jsx-runtime", "preact", "preact/hooks", "../hooks/useQuery", "../utils/environment", "../hooks/useMutation"], function (require, exports, jsx_runtime_1, preact_1, hooks_1, useQuery_1, environment_1, useMutation_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.RecentActivityProvider = exports.RecentActivityContext = void 0;
    exports.RecentActivityContext = (0, preact_1.createContext)({ values: { todaysRecentActivities: [], restRecentActivities: [] }, append: undefined });
    const RecentActivityProvider = ({ accessToken, children }) => {
        const [todaysRecentActivities, setTodaysRecentActivities] = (0, hooks_1.useState)([]);
        const [restRecentActivities, setRestRecentActivities] = (0, hooks_1.useState)({});
        const { invoke: postRecentActivity } = (0, useMutation_1.useRecentActivityMutation)(accessToken);
        const appendRecentActivity = (0, hooks_1.useCallback)((recentActivity) => {
            const todaysRecentActivitiesWithoutCurrent = todaysRecentActivities
                .filter(ra => new Date(ra.creationDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0))
                .filter(ra => ra.url !== recentActivity.url)
                .filter(ra => ra.entityId === undefined || ra.entityId !== recentActivity.entityId);
            setTodaysRecentActivities([recentActivity, ...todaysRecentActivitiesWithoutCurrent]);
            if (accessToken) {
                postRecentActivity(Object.assign(Object.assign({}, recentActivity), { url: recentActivity.url, creationDate: undefined }));
            }
        }, [todaysRecentActivities, postRecentActivity, accessToken]);
        const { loading, result, error } = (0, useQuery_1.useRecentActivityQuery)(accessToken);
        (0, hooks_1.useEffect)(() => {
            var _a, _b;
            if (!loading && error) {
                (0, environment_1.debug)("[OCC] recent activity query failed", error);
                setTodaysRecentActivities([]);
                setRestRecentActivities([]);
            }
            if (!loading && !error && result) {
                (0, environment_1.debug)("[OCC] recent activity query result", result);
                const addDefaultUtcTimezone = (recentActivity) => (Object.assign(Object.assign({}, recentActivity), { creationDate: recentActivity.creationDate && recentActivity.creationDate.includes("Z") ? recentActivity.creationDate : `${recentActivity.creationDate}Z` }));
                const recentActivities = (_b = (_a = result.items) === null || _a === void 0 ? void 0 : _a.reduceRight((acc, curr) => curr.entityId === undefined ? [curr, ...acc] : (acc.some(ra => curr.entityId === ra.entityId) ? acc : [curr, ...acc]), []).map(addDefaultUtcTimezone)) !== null && _b !== void 0 ? _b : [];
                const todaysRecentActivities = recentActivities
                    .filter(ra => new Date(ra.creationDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0));
                setTodaysRecentActivities(todaysRecentActivities);
                const restRecentActivities = recentActivities
                    .filter(ra => new Date(ra.creationDate).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0))
                    .reduce((acc, curr) => {
                    const dateValue = Date.parse(new Date(curr.creationDate).toDateString());
                    acc[dateValue] = acc[dateValue] ? [...acc[dateValue], curr] : [curr];
                    return acc;
                }, {});
                setRestRecentActivities(restRecentActivities);
            }
        }, [loading, error, result]);
        return ((0, jsx_runtime_1.jsx)(exports.RecentActivityContext.Provider, { value: { values: { todaysRecentActivities, restRecentActivities }, append: appendRecentActivity }, children: children }));
    };
    exports.RecentActivityProvider = RecentActivityProvider;
});
