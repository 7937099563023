define(["require", "exports", "preact/jsx-runtime", "@os/os-components/lib/OsHooks/useTranslationFor", "./errorPage.scss"], function (require, exports, jsx_runtime_1, useTranslationFor_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.ErrorPage = void 0;
    const ErrorPage = ({ errorType, environment }) => {
        let config = { trTitle: "", trSubtitle: "", trDescription: "", img: "", backButton: "", dasboardButton: "", oracleComButton: "" };
        config.trTitle = (0, useTranslationFor_1.useTranslationFor)(`error.${errorType}.title`);
        config.trSubtitle = (0, useTranslationFor_1.useTranslationFor)(`error.${errorType}.subtitle`);
        config.trDescription = (0, useTranslationFor_1.useTranslationFor)(`error.${errorType}.description`);
        config.img = errorType === "invalidRoute" ? "styles/images/page-not-found.png" : "styles/images/maintenance.png";
        config.backButton = (0, useTranslationFor_1.useTranslationFor)("back");
        config.dasboardButton = (0, useTranslationFor_1.useTranslationFor)("goToDashboard");
        config.oracleComButton = (0, useTranslationFor_1.useTranslationFor)("goToOracle");
        return ((0, jsx_runtime_1.jsxs)("div", { className: "errorPage oj-flex oj-flex-item", style: { marginTop: "68px" }, children: [(0, jsx_runtime_1.jsx)("div", { class: "strip" }), (0, jsx_runtime_1.jsxs)("div", { className: "container oj-flex oj-flex-item oj-sm-12  oj-sm-align-items-center oj-sm-padding-12x-horizontal", children: [(0, jsx_runtime_1.jsxs)("div", { className: "oj-sm-12 oj-lg-6", children: [(0, jsx_runtime_1.jsx)("div", { className: "title", children: config.trTitle }), (0, jsx_runtime_1.jsx)("div", { className: "subtitle oj-sm-margin-6x-top", children: config.trSubtitle }), (0, jsx_runtime_1.jsx)("div", { className: "description  oj-sm-margin-2x-top oj-sm-margin-6x-bottom", children: config.trDescription }), (0, jsx_runtime_1.jsxs)("div", { children: [errorType !== "fatal" &&
                                            (0, jsx_runtime_1.jsx)("oj-button", { id: "back", chroming: "callToAction", class: "oj-sm-padding-2x-end", onojAction: () => history.back(), children: config.backButton }), errorType !== "invalidRoute" &&
                                            (0, jsx_runtime_1.jsx)("oj-button", { id: "goToOracleCom", chroming: errorType === "fatal" ? "callToAction" : "outlined", class: "oj-sm-padding-2x-end", onojAction: _ => (window.location.href = environment.oracleCom), children: config.oracleComButton }), errorType === "invalidRoute" &&
                                            (0, jsx_runtime_1.jsx)("oj-button", { id: "gotoDashboard", onojAction: () => location.replace("/"), children: config.dasboardButton })] })] }), (0, jsx_runtime_1.jsx)("div", { className: "oj-sm-12 oj-lg-6 oj-md-down-hide oj-flex oj-sm-flex-items-initial oj-sm-justify-content-center", children: (0, jsx_runtime_1.jsx)("img", { src: config.img, class: "oj-flex-item" }) })] })] }));
    };
    exports.ErrorPage = ErrorPage;
});
